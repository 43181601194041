.nav-sm .container.body .right_col {
    overflow: auto;
    height: 100vh;
}

.modal {
    background-color: rgba(0,0,0,0.5);
    z-index: 9999 !important;
}

.modal-content {
    height: 90vh;
}

.modal-body {
    overflow-y: auto;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

#react-confirm-alert {
    z-index: 10000;
    position: relative;
}
