.nav.side-menu>li.current-page, .nav.side-menu>li.active {
    border: none !important; 
}

.nav.side-menu>li.active>a {
    color: #ffffff !important;
    background: #000000 !important;
}

@media (min-width: 600px) {
    .top_nav {
      display: none !important;
    }
  }