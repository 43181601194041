body {
  color: #000000 !important;
  margin: 0;
  font-family: neue-haas-unica, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #000000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* THEME OVERRIDES */

.nav_title, .left_col {
  background-color: #fdf40e !important;
}

body .container.body .right_col {
  background-color: #ffffff !important;
}

.nav.side-menu>li>a, .nav.child_menu>li>a, .menu-item {
  color: #000000 !important;
}
.nav.side-menu>li:hover {
  background-color: #000000 !important;
}

.btn-success {
  background-color: #ffffff !important;
  border: 1px solid #000000 !important;
  color: #000000 !important;
}

.btn-success:hover {
  background-color: #000000 !important;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
}

table.jambo_table thead {
  background-color: #000000 !important;
  color: #ffffff !important;
}

table.jambo_table tbody tr:hover td {
  background: rgba(253,244,14,0.07) !important;
  border-top: 1px solid rgba(253,244,14,0.11) !important;
  border-bottom: 1px solid rgba(253,244,14,0.11) !important;
}

input + div {
  color: red;
  margin-bottom: 10px;
}